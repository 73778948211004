<template>
  <div class="aboutus-cnt4">
    <h6 class="h6">服务协议 - Service agreement</h6>
    <div class="cnt">
      <h6 class="h5">免责条款</h6>
      <p class="p">在您注册网站和使用微微定网服务之前，请仔细阅读下面的条款。在条款中，您一方以"您"和"您的"表示，本网站则以"我
们"、"我们的"表示。</p>
      <h6 class="h5">一、服务条款的确认与接纳</h6>
      <p class="p">请您务必定期、仔细阅读本网站的《服务条款》及其他相关条款内容，在使用本网站所提供服务前，请您先接受和确认此
《免责条款》，否则请停止注册网站会员并停止使用本网站服务。</p>
      <h6 class="h5">二、服务所需的设备和其他条件</h6>
      <p class="p">为了让您顺利享用微微定网提供的服务，请您自备接入互联网所需的硬件和软件，自行支付所有互联网的连接费用。</p>
      <h6 class="h5">三、用户个人资料的使用和填写</h6>
      <p class="p">当您在本网站及附属软件上进行用户注册登记、订制个性化印品以及参加网上支付等活动时，在您同意及确认下，本网站将
通过注册表格、订单等形式要求您提供部分个人资料。个人资料包括：真实姓名、性别、电话、通信地址、电子邮件等，以
上个人资料本网站仅用作确保您能够更好的享用本网站为您提供的服务，本网站保证任何情况下都不会主动向任何第三方提
供这些信息，除国家法律、法规要求披露的除外。</p>
      <p class="ps">鉴于我们所提供的网络服务的重要性，用户应同意：</p>
      <ul class="ul">
        <li><span>·</span> 提供真实、有效、完整的个人资料；</li>
        <li><span>·</span> 及时更新注册资料；</li>
        <li><span>·</span> 若用户代表一家公司或其他法律主体在本网站登记，则用户应声明和保证该公司或其他法律主体接受本服务"条款"的约束。</li>
      </ul>
      <h6 class="h5">四、用户帐号、密码和安全性</h6>
      <p class="p">用户注册成功后，每个用户都要对以其用户帐号进行的一切行为、活动和事件负法律责任，请务必妥善保管账号、密码的安
全，并保证不向其他任何人泄露您在注册时向本网站提交的电子邮箱、用户名、密码及安全问题答案等信息。如非因本网站
原因造成您泄露账户密码或相关信息的，您应及时通知本网站，以减少可能发生的损失，因上述原因导致的损失需由您自行
承担。</p>
      <p class="p">用户若发现任何盗用、冒用其用户帐号和密码的进行非法行为或危害网站安全的情况，应立即通知本网站并要求暂停服务。
本网站将积极响应您的要求；但您理解，对您的要求采取行动需要合理期限，在此之前，本网站对已执行的指令及(或)所导
致的您的损失不承担任何责任。</p>
      <h6 class="h5">五、网站使用</h6>
      <p class="p">1、本网站会根据实际情况对现有服务项目、流程进行适当调整以及变更，本网站会在调整、变更后在网站予以通知，请用
户使用服务前仔细阅读并了解新的服务项目、流程，否则对由此产生的问题，本网站不承担任何责任。</p>
      <p class="p">2、一切上传本网站的印刷文件，您都保证会安全保管原始文档，本网站将不对任何非本网站原因导致您原始文档丢失的行
为负责，也不对任何导致您上传的印刷文件丢失的原因负责。</p>
      <p class="p">3、如果您的印刷文件内容违反国家法律规定或超过本条款规定的限制，我们会立即删除，印刷文件不予退还。如果一经发
现您有违反相关法律法规的行为，我们将依法上报该情况至相关国家监管部门。</p>
      <p class="ps">4、如果您通过本网站发送订单，您需了解并同意：</p>
      <ul class="ul">
        <li><span>·</span> 当您的印品订单上传到网站并被确认后，订单将不可取消。</li>
        <li><span>·</span> 如果您的印刷文件含有违反国家法律、法规的内容，我们有权撤销您的订单。</li>
        <li><span>·</span> 如果您收到的个性化产品出现质量问题，我们将尽快协助您解决问题。</li>
        <li><span>·</span> 如果因您的主观原因而造成误印，本网站将尽量与您协商解决，但由此发生的费用由您自行承担。</li>
      </ul>
      <h6 class="h5">六、服务条款的修改与完善</h6>
      <p class="p">1、本网站有权修改并完善服务条款（包括但不限于本条款）和服务内容。</p>
      <p class="p">2、在您使用本网站提供的服务时，应当接受当时最新版本的服务条款的约束。请您务必定期、仔细审阅本服务条款及刊登
在本网站上的其他条款。如果您不同意更新过的条款，请您暂停使用本网站提供的服务，并有权注销会员资格。如您在条款
更新后继续使用我们的服务，我们将默认您已接受我们更新的条款。</p>
      <p class="p">3、本网站保留随时修改或中断服务而不需通知您的权利，本网站行使修改或中断服务的权利，无须对您或第三方负责。</p>
      <h6 class="h5">七、版权</h6>
      <p class="p">1、经由我们向您提供的所有信息，包括但不限于文字、软件、声音、相片（图片）、录像、图表、商标和其它商业信息资
料，均受中华人民共和国著作权法、商标法、专利法和其它财产法保护。任何人只有在得到书面授权的情况下，方可使用这
些信息；如未取得我们事先的书面授权，您将不得擅自使用、复制、发行这些信息、软件、代码、商标或资料，也不得修改
、创造衍生的可能侵犯在先权利的作品。</p>
      <p class="p">2、当您提交数码相片（图片）、印品文件用于印刷时，您应当保证并确认您拥有所有相片（图片）及有关文字的著作权、
使用资格与权益。我们声明，对您印品中的任何相片（图片）、文字我们均不享有任何所有权。</p>
      <h6 class="h5">八、隐私保护</h6>
      <p class="p">我们尊重您个人的隐私。未经您的同意，我们将不会向任何第三方披露您提交的个人信息、相片（图片）、文字、印品文件。</p>
      <h6 class="h5">九、相片（图片）内容的限制和禁止</h6>
      <p class="p">您保证已经明白、确认并且同意，您提交的用于印品的相片（图片）内容、文字内容必须符合中华人民共和国法律法规的规
定，您不可储存、刊登或分发任何非法的、为法律所禁止的內容。</p>
      <p class="ps">这些内容包括但不限于：</p>
      <ul class="ul">
        <li><span>·</span> 侵犯或可能侵犯任何第三方知识产权或其他合法权益的内容；</li>
        <li><span>·</span> 构成或可能构成反社会或刑事违法（包括歧视、谋杀、虐待、人身伤害、跟踪、儿童色情及性骚扰）的內容；</li>
        <li><span>·</span> 违反或可能违反公共秩序、保密规定和社会公德的內容；</li>
        <li><span>·</span> 危害本网站或其他网站安全的电脑病毒或工具；</li>
        <li><span>·</span> 煽惑、建议或鼓励任何违法活动的内容；</li>
        <li><span>·</span> 有关任何个人（除您以外）的个人资料（如姓名、电话号码、地址等）。</li>
        <li><span>·</span> 所有带有上述内容的相片（图片）和资料都不予退还。如果一经发现您有违反相关法律法规的行为，我们将依法上报该情
况至相关国家监管部门。</li>
      </ul>
      <h6 class="h5">十、通告</h6>
      <p class="p">我们会根据需要，通过网站页面的公告、电子邮件或常规的信件向您通告信息，您保证并确认在注册本网站时就已经默认知
晓并接受此事项。</p>
      <h6 class="h5">十一、信息的储存及限制</h6>
      <p class="p">本网站有权判定用户行为是否符合本网站服务条款的要求，如果用户违反本网站服务条款的规定，本网站有权暂停其帐号的
使用。本网站对用户上传的印品文件予以保存60天，超过60天即删除该印品文件，本网站不承诺长期保存您的印品文件。
如果用户在连续720天内没有登录、查看或使用用户帐号，并且其帐户余额为0，本网站将视为用户自行放弃该帐号的使用
权，本网站将保留暂停为其提供服务的权利。</p>
      <h6 class="h5">十二、终止服务</h6>
      <p class="p">用户或本网站可根据实际情况中断一项或多项服务。本网站无须为中断服务而对任何个人或第三方负责。用户若反对任何服
务条款、对更新条款的修改有异议或对本网站服务不满，用户可以行使如下权利：</p>
      <p class="p">(1)不再使用本网站信息服务；</p>
      <p class="p">(2)通知本网站停止向该用户提供服务。</p>
      <p class="p">终止服务后，用户使用本网站服务的权利立即终止，用户不再对本网站享有任何权利，本网站也不再对用户负有任何向用户
或第三方传送任何未处理的信息或未完成的服务的义务。</p>
      <h6 class="h5">十三、其它条款</h6>
      <p class="p">本服务条款适用中华人民共和国的法律、法规。您与我们一致同意一切因使用本条款服务而引起的或与本服务条款有关的争
议均提交中华人民共和国法院解决。若本服务条款的任何条款是无效的或不可执行的，则该条款将尽可能的在与其原意匹配
的情况下，用有效、可执行的条款替代，其它条款继续有效。您同意，我们对本条款中任何权利的放弃并不构成对其它条款
权利的放弃。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutus-cnt4',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-cnt4 {
  width: 946px;float:right;background: #fff;border-radius: 5px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .h5 {
      font-weight:normal;font-size: 16px;color:#555;margin-bottom:10px;
    }
    .p {
      font-size: 16px;color:#555555;line-height: 30px;margin-bottom:30px;
    }
    .ps {
      margin-bottom:0px;font-size: 16px;color:#555555;line-height: 30px;
    }
    .ul {
      margin-bottom:30px;
      li {
        font-size: 16px;color:#555;margin-bottom:0px;
        span {
          font-size: 36px;vertical-align: -6px;
        }
      }
    }
  }
}
</style>
