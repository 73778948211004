<!-- 关于我们左侧 -->
<template>
  <div class="aboutus-lt" >
    <button :class='{choice:type==0}' @click='links(0)'>关于我们</button>
    <button :class='{choice:type==1}' @click='links(1)'>联系我们</button>
    <button :class='{choice:type==2}' @click='links(2)'>商务合作</button>
    <button :class='{choice:type==3}' @click='links(3)'>版权声明</button>
    <button :class='{choice:type==4}' @click='links(4)'>服务协议</button>
  </div>
</template>
<script>
export default {
  name: 'aboutus-lt',
  props: {
    type: Number
  },
  methods:{
    links(v){
      switch(v) {
        case 0:
          this._router({then:()=>{
            this.$router.push({path: "/aboutus.html"})
          }})
          break;
        case 1:
          this._router({then:()=>{
            this.$router.push({path: "/aboutuscontact.html"})
          }})
          break;
        case 2:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusteamwork.html"})
          }})
          break;
        case 3:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusstate.html"})
          }})
          break;
        case 4:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusservice.html"})
          }})
          break;
        default:
          break;
      }
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-lt {
  width: 234px;background: #fff;border-radius: 5px;float:left;
  button {
    width: 234px;height: 60px;background: none;text-align: left;padding-left:30px;position: relative;color:#878787;font-weight: bold;
    &:after {
      content: '';display: none;width: 6px;height: 48px;background: #fe8385;border-radius: 5px;position: absolute;top:6px;left:0px;
    }
    &:hover {
      background: #fe8385;color:#fff;
    }
    &:first-child {
      border-top-left-radius: 5px;border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;
    }
  }
  .choice {
    color:#262626;
    &:after {
      display: block;
    }
  }
}
</style>
