<!-- 面包屑导航 -->
<template>
  <div class="crumbs">
    <p v-if='type==0'><span  class="other type" @click='initSc(i)' v-for='i,s in location'><i v-if='s>0'>></i>{{i.Name}}</span></p>
    <p v-if='type==1'>
      <span @click='allresult' class="star starAll">全部结果</span>
      <span   class="other" @click='initSc(i)' v-for='i,s in location'><i>></i>{{i.Name}}</span>
      <!-- <span  class="other"><i>></i>"{{this.$store.state.searchTxt?this.$store.state.searchTxt:"全部"}}"</span> -->
      <span v-if='!location||location.length<1' class="other"><i>></i>"{{this.$store.state.searchTxt?this.$store.state.searchTxt:"全部"}}"</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'crumbs',
  props: {
    location: Array,
    type:{
      default:0,
      type:Number
    }
  },
  methods:{
    initSc(i){
      // 选中分类
      this.$emit('exportCls',i)
    },
    allresult(){
      // 全部结果
      this.$emit('exportallresult')
    }
  }
}
</script>
<style  lang="scss" scoped>
.crumbs {
  width: 1200px;margin:0 auto;color:#cac8ca;height: 36px;line-height: 36px;
  span {
    font-size: 14px;color:#797979;
    i {
      font-style: normal;margin:0 3px;
    }
    &:last-child {
      // color:#333;
    }
    &:nth-child(1) {
      cursor: pointer;
    }
  }
  .starAll {
    cursor: pointer;font-size: 12px;
    i {
      margin:0 15px;color:#ccc;
    }
    &:hover {
      // color:#333;
      opacity: 0.8;
    }
  }
  .other {
    cursor: pointer;font-size: 12px;
    i {
      margin:0 15px;color:#ccc;
    }
    &:hover {
      // color:#333;
      opacity: 0.8;
    }
  }
  .type {
    font-size: 12px;
    color:#797979;
    i {
      margin:0 15px;color:#ccc;
    }
    &:last-child {
      cursor: auto;
      &:hover {
        // color:#333;
        opacity: 1;
      }
    }
  }
  p {
    span {
      &:last-child {
        font-weight: bold;
      }
    }
  }
}
</style>
